<template>
	<div class="login">
		<el-form ref="loginForm" :model="loginForm" :rules="rules" class="login-box" label-width="80px">
			<h2 class="login-title" v-text="platformName"/>
			<el-form-item label="账号" prop="username">
				<el-input v-model="loginForm.username" placeholder="请输入账号" type="text"/>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input v-model="loginForm.password" placeholder="请输入密码" type="password"/>
			</el-form-item>

			<el-form-item class="code" label="验证码" prop="code">
				<el-input v-model="loginForm.code" placeholder="请输入验证码" type="text"/>
				<img :src="verificationImg" class="cursor-pointer height-40-px" @click="getVerificationCode"/>
			</el-form-item>
			<div class="text-right">
				<el-checkbox v-model="loginForm.rememberMe"> 记住我</el-checkbox>
			</div>
			<el-form-item class="submit margin-0 w-100">
				<el-button :loading="loading" class="w-100" size="medium" type="primary" @click.prevent="handleLogin"
				>
					<span v-if="!loading">登 录</span>
					<span v-else>登 录 中...</span>
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import {getCodeImg} from '@/api/login'
import {encrypt} from '@/utils/rsaEncrypt'
import Cookies from 'js-cookie'
import Config from '@/settings'
import {mapState} from 'vuex'

export default {
	name: 'SystemLogin',
	data() {
		return {
			loginForm: {
				username: '',
				password: '',
				code: '',
				uuid: '',
				rememberMe: false
			},
			verificationImg: '',
			loading: false,
			rules: {
				username: [
					{required: true, message: '账号不可为空', trigger: 'blur'},
					{
						min: 2,
						max: 25,
						message: '用户名只能输入英文数字2-25个字符!',
						trigger: 'blur'
					}
				],
				password: [{required: true, message: '密码不可为空', trigger: 'blur'}],
				code: [{required: true, message: '验证码不可为空', trigger: 'blur'}]
			}
		}
	},
	created() {
		this.getVerificationCode()
		this.getCookie()
		this.point()
		//window.addEventListener('keydown', this.handleLogin)
	},
	computed: {
		...mapState('settings', ['platformName'])
	},
	mounted() {
		this.isAutoLogin()
	},
	methods: {
		getVerificationCode() {
			let that = this

			getCodeImg().then(response => {
				if (response.code === 200) {
					that.verificationImg = 'data:image/png;base64,' + response.data.img
					that.loginForm.uuid = response.data.uuid
				}
			})
		},
		handleLogin() {
			this.$refs.loginForm.validate(valid => {
				const user = {
					username: this.loginForm.username,
					password: this.loginForm.password,
					rememberMe: this.loginForm.rememberMe,
					code: this.loginForm.code,
					uuid: this.loginForm.uuid
				}

				if (user.password !== this.cookiePass) {
					user.password = encrypt(user.password)
				}
				if (valid) {
					this.loading = true
					if (user.rememberMe) {
						Cookies.set('username', user.username, {
							expires: Config.passCookieExpires
						})
						Cookies.set('password', user.password, {
							expires: Config.passCookieExpires
						})
						Cookies.set('rememberMe', user.rememberMe, {
							expires: Config.passCookieExpires
						})
					} else {
						Cookies.remove('username')
						Cookies.remove('password')
						Cookies.remove('rememberMe')
					}
					this.$store
							.dispatch('Login', user)
							.then(() => {
								this.loading = false
								this.$router.push({path: this.redirect || '/'})
							})
							.catch(() => {
								this.loading = false
								this.getVerificationCode()
							})
				} else {
					return false
				}
			})
		},
		getCookie() {
			const username = Cookies.get('username')

			let password = Cookies.get('password')
			const rememberMe = Cookies.get('rememberMe')

			this.cookiePass = password === undefined ? '' : password
			password = password === undefined ? this.loginForm.password : password
			this.loginForm = {
				username: username === undefined ? this.loginForm.username : username,
				password: password,
				rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
				code: ''
			}
		},
		point() {
			const point = Cookies.get('point') !== undefined
			if (point) {
				this.$notify({
					title: '提示',
					message: '当前登录状态已过期，请重新登录！',
					type: 'warning',
					duration: 5000
				})
				Cookies.remove('point')
			}
		},
		isAutoLogin() {
			const that = this
			let uname = this.$route.query.username
			if (uname) {
				that.loginForm = {username: uname, password: '123456', uuid: '123456', code: 1234, rememberMe: false}
				setTimeout(() => {
					that.handleLogin()
				}, 100)
			}
		}
	},
	unmounted() {
		window.removeEventListener('keydown', this.handleLogin, false);
	}
}
</script>
/* eslint-disable */
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: url(../assets/img/background/login_bg.png);
  .login-box {
    border: 1px solid #dcdfe6;
    width: 350px;
    margin: auto;
    padding: 35px 35px 15px 35px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    background: #fcfcfc;
  }
  .login-title {
    text-align: center;
    margin: 0 auto 40px auto;
    color: #303133;
  }
  h2 {
    font-size: 24px;
  }
  :deep(.el-form-item__content) {
    margin: 0;
    text-align: center;
  }
  :deep(.code .el-form-item__content) {
    display: flex;
  }
}
</style>
