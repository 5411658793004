import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';

const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAM/h6DLINih0PsyevTytMlPhoTwEErjr\n' +
    'S4h1xiVSRXqmf6h2c6Ehaf7LjfI0m0VxYeuVun6xu+TvSdI9jZNwlQcCAwEAAQ==';

export function encrypt(txt) {
    const encryptor = new JSEncrypt();

    encryptor.setPublicKey(publicKey);
    return encryptor.encrypt(txt);
}

